<template>
  <div class="w-screen md:w-3/4 py-4 mx-auto">
      <a href="mailto:mehtaabgill@hotmail.com" class="text-purple-400 font-bold border-2 border-purple-400 rounded-md p-1">Contact Site Owner</a>
  </div>
</template>

<script>
export default {
    name:'Footer'
}
</script>